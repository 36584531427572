import i18n from '@/i18n'
import moment from 'moment'
import reminderApi from '@/api/reminder.api'
import thingApi from '@/api/thing.api'
import personApi from '@/api/person.api'
import AutocompleteComponent from '@/components/commons/autocomplete/AutocompleteComponent.vue'
import { getChildrenFromList } from '@/tools/functions'

export default {
  name: 'ReminderConfirmationDialog',
  components: {
    AutocompleteComponent
  },
  props: {
    showDialog: Boolean,
    reminderConfirmation: Object,
    enterprises: Array
  },
  data: () => ({
    validForm: false,
    validationRules: {
      required: (value) => !!value || `${i18n.t('required')}`,
      min: (value, min) => value == null || min == null || isNaN(min) || value >= min || `${i18n.t('minValue', { min })}`,
      max: (value, max) => value == null || max == null || isNaN(max) || value <= max || `${i18n.t('maxValue', { max })}`,
      noNegative: (value) => parseFloat(value) >= 0 || `${i18n.t('maxValue', { max: 0 })}`
    },
    menu1: false,
    menu2: false,
    menu3: false,
    objects: [],
    types: [],
    allThings: [],
    filteredThings: [],
    allPeople: [],
    nowDate: new Date().toISOString().slice(0, 10)
  }),
  computed: {
  },
  created () {
    this.loadSelectors()
  },
  methods: {
    cancel () {
      this.$emit('closeDialog')
      this.$refs.form.resetValidation()
    },
    save () {
      if (this.reminderConfirmation.objectId) {
        if (this.enterprises.find(e => e.id === this.reminderConfirmation.objectId)) {
          this.reminderConfirmation.objectType = 'enterprise'
        } else if (this.allPeople.find(e => e.id === this.reminderConfirmation.objectId)) {
          this.reminderConfirmation.objectType = 'person'
        } else if (this.filteredThings.find(e => e.id === this.reminderConfirmation.objectId)) {
          this.reminderConfirmation.objectType = 'thing'
        }
      }
      this.reminderConfirmation.doneDate = moment(this.reminderConfirmation.date + ' ' + this.reminderConfirmation.time, 'YYYY-MM-DD hh:mm').unix()
      this.$emit('saveDialog', this.reminderConfirmation)
      this.$refs.form.resetValidation()
    },
    async loadSelectors () {
      this.allPeople = (await personApi.getAll()).data
      this.allThings = (await thingApi.getNested()).data
      // se filtra allThings para mostrar solo equipos y no grupos
      this.allThings.map(thing => {
        if (thing.nodeType === 'GROUP') {
          getChildrenFromList(thing.children, this.filteredThings)
        }
      })
      this.filteredThings = this.filteredThings.concat(this.allThings.filter(f => f.nodeType === 'THING'))
      this.objects = this.filteredThings.concat(this.allPeople).concat(this.enterprises)
      this.types = (await reminderApi.getAllTypesByEnterprises(this.enterprises.map(e => e.id))).filter(t => t.visible).concat((await reminderApi.getAllPlansByEnterprises(this.enterprises.map(e => e.id))).filter(t => t.visible))
    },
    objectSelectedFunction (newValue) {
      this.reminderConfirmation.objectId = newValue && newValue.id ? newValue.id : newValue
    },
    typeSelectedFunction (newValue) {
      this.reminderConfirmation.reminderTypeId = newValue && newValue._id ? newValue._id : newValue
    }
  }
}
