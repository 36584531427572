import i18n from '@/i18n'
import SelectorDialog from '@/components/commons/selector/dialog/SelectorDialog.vue'
import { filterRulesMapped } from '@/tools/filterRules'
import reminderApi from '@/api/reminder.api'
import enterpriseApi from '@/api/enterprise.api'
import { mapActions, mapMutations } from 'vuex'
import { SnackbarStyle } from '@/constants/constants'
import ListTreeViewComponent from '@/components/commons/list/tree-view/ListTreeViewComponent.vue'
import ReminderConfirmationDialog from './dialog/ReminderConfirmationDialog.vue'

export default {
  name: 'ReminderConfirmationComponent',
  components: {
    ListTreeViewComponent,
    SelectorDialog,
    ReminderConfirmationDialog
  },
  data: () => ({
    tableHeaders: [],
    tableFilterRules: {},
    tableData: [],
    tableDataKey: '_id',
    tableComponentButtons: [],
    rowActions: [
      {
        id: 1,
        name: i18n.t('reminder.confirmation.titleNew'),
        color: 'green',
        icon: 'fa-check-double'
      }
    ],
    showConfirmationDialog: false,
    confirmationDto: {},
    enterprises: []
  }),
  computed: {
  },
  async created () {
    this.tableComponentButtons.push(
      {
        id: 'edit-dialog',
        tooltip: i18n.t('add'),
        icon: 'add',
        action: this.showDialog.bind(this),
        selectedData: false
      })
    this.resetFields()
    this.getData()
    const enterpriseId = JSON.parse(localStorage.getItem('enterpriseId'))
    this.enterprises = (await enterpriseApi.getMultiple([enterpriseId])).data
  },
  methods: {
    ...mapActions({
      'showSnackbar': 'snackbar/showSnackbar',
      'closeSnackbar': 'snackbar/closeSnackbar'
    }),
    ...mapMutations('dialog', {
      openDialog: 'openDialog',
      closeDialog: 'closeDialog'
    }),
    /**
     * Refrescar la data
     */
    refreshTable () {
      this.getData(true)
    },
    async getData (refresh = false) {
      this.loadingTable = true
      reminderApi.getToConfirm().then(response => {
        this.tableData.cleanAndUpdate(response.data.data)
        if (!refresh) {
          this.tableHeaders.cleanAndUpdate(response.data.headers)
          this.setTableFilterRules()
        }
        this.loadingTable = false
      })
    },
    setTableFilterRules () {
      this.tableFilterRules = {}
      this.tableHeaders.forEach(header => {
        this.tableFilterRules[header.value] = filterRulesMapped[header.filterType]
      })
    },
    rowButtonClicked (button, data) {
      switch (button.id) {
        case 1:
          this.confirmationDto = this.reminderToConfirmation(data)
          console.log(this.confirmationDto)
          this.showConfirmationDialog = true
          // confirmar
          break
        default:
          console.log('default')
      }
    },
    showDialog () {
      this.showConfirmationDialog = true
      this.confirmationDto.doneValues = [ { parameter: 'hours' }, { parameter: 'km' } ]
      this.confirmationDto.maintenanceType = 'preventive'
    },
    closeDialog () {
      this.showConfirmationDialog = false
      this.resetFields()
    },
    async saveDialog (confirmation) {
      this.showConfirmationDialog = false
      await reminderApi.createConfirmation(confirmation).then(() => {
        this.showSnackbar({ visible: true, text: this.$t('reminder.confirmation.saveSuccess'), timeout: 9500, style: SnackbarStyle.SUCCESS })
      })
      this.resetFields()
    },
    resetFields () {
      this.confirmationDto = {}
    },
    reminderToConfirmation (reminder) {
      const confirmationDto = {
        maintenanceType: 'preventive',
        reminderId: reminder._id,
        reminderAutomatic: reminder.automatic
      }
      confirmationDto.doneValues = reminder.expirations.filter(e => e.parameter !== 'date').map(e => ({
        parameter: e.parameter,
        doneValue: (e.parameter === 'hours'
          ? parseFloat(reminder.objectInfo.hoursValues.find(h => h.id === e.selectedHourType).lastValue)
          : parseFloat(reminder.objectInfo.kmValues.find(h => h.id === e.selectedKmType).lastValue)) || (reminder.state === 'ok' ? e.start : e.nextAlert),
        maxValue: e.parameter === 'hours'
          ? parseFloat(reminder.objectInfo.hoursValues.find(h => h.id === e.selectedHourType).lastValue)
          : parseFloat(reminder.objectInfo.kmValues.find(h => h.id === e.selectedKmType).lastValue)
      }))
      confirmationDto.repeatAgain = reminder.automatic ? null : reminder.expirations.map(e => ({
        parameter: e.parameter,
        repeat: reminder.state === 'ok' ? (e.parameter === 'date' ? new Date(e.expirationDate).toISOString().slice(0, 10) : e.nextAlert)
          : (e.parameter === 'date' ? undefined : confirmationDto.doneValues.find(v => v.parameter === e.parameter).doneValue + e.repeatEvery),
        minValue: e.parameter === 'date' ? null : (e.parameter === 'hours'
          ? parseFloat(reminder.objectInfo.hoursValues.find(h => h.id === e.selectedHourType).lastValue)
          : parseFloat(reminder.objectInfo.kmValues.find(h => h.id === e.selectedKmType).lastValue))
      }))
      return confirmationDto
    }
  }
}
