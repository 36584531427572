import service from '@/middleware'

const reminderApi = {
  async getPaginated (pageSize, pageNumber) {
    let result
    if (pageSize && pageSize > 0 && pageNumber && pageNumber > 0) {
      result = await service.http.get(`/api/reminder?pageSize=${pageSize}&pageNumber=${pageNumber}`)
    } else {
      result = await service.http.get(`/api/reminder`)
    }
    return result
  },
  async getToConfirm () {
    const result = await service.http.get(`/api/reminder/to-confirm`)
    return result
  },
  async getPaginatedForUser (pageSize, pageNumber) {
    let result
    if (pageSize && pageSize > 0 && pageNumber && pageNumber > 0) {
      result = await service.http.get(`/api/reminder/for-user?pageSize=${pageSize}&pageNumber=${pageNumber}`)
    } else {
      result = await service.http.get(`/api/reminder/for-user`)
    }
    return result
  },
  async getById (id) {
    const result = await service.http.get(`/api/reminder/${id}`)
    return result
  },
  async create (reminders) {
    const result = await service.http.post('/api/reminder', reminders)
    return result
  },
  async createMultiple (remindersInfo) {
    const result = await service.http.post('/api/reminder/multiple', remindersInfo)
    return result
  },
  async update (reminder) {
    const result = await service.http.put('/api/reminder', reminder)
    return result
  },
  async delete (id) {
    const result = await service.http.delete(`/api/reminder/${id}`)
    return result
  },
  async multipleDelete (ids) {
    const result = await service.http.put(`/api/reminder/multiple-delete`, ids)
    return result
  },
  async getTotalCount () {
    const result = await service.http.get(`/api/reminder/total-count`)
    return result
  },
  async getTotalCountForUser () {
    const result = await service.http.get(`/api/reminder/total-count-for-user`)
    return result
  },
  async getTypesPaginated (pageSize, pageNumber, enterpriseId) {
    let result
    if (pageSize && pageSize > 0 && pageNumber && pageNumber > 0 && enterpriseId) {
      result = await service.http.get(`/api/reminder/type?pageSize=${pageSize}&pageNumber=${pageNumber}&enterpriseId=${enterpriseId}`)
    } else if (pageSize && pageSize > 0 && pageNumber && pageNumber > 0) {
      result = await service.http.get(`/api/reminder/type?pageSize=${pageSize}&pageNumber=${pageNumber}`)
    } else {
      result = await this.getAllTypes(enterpriseId)
    }
    return result
  },
  async getAllTypes (enterpriseId) {
    let result
    if (enterpriseId) {
      result = await service.http.get(`/api/reminder/type?enterpriseId=${enterpriseId}`)
    } else {
      result = await service.http.get(`/api/reminder/type`)
    }
    return result.data
  },
  async getAllTypesByEnterprises (enterpriseIds) {
    let result
    result = await service.http.post(`/api/reminder/type/enterprises`, enterpriseIds)
    return result.data
  },
  async getTypesById (id) {
    const result = await service.http.get(`/api/reminder/type/${id}`)
    return result
  },
  async createType (type) {
    const result = await service.http.post('/api/reminder/type', type)
    return result
  },
  async updateType (type) {
    const result = await service.http.put('/api/reminder/type', type)
    return result
  },
  async deleteType (id) {
    const result = await service.http.delete(`/api/reminder/type/${id}`)
    return result
  },
  async changeTypeVisibility (id, visible, enterpriseId) {
    const result = await service.http.put(`/api/reminder/type/change-visibility`, { id, visible, enterpriseId })
    return result
  },
  async multipleDeleteType (ids) {
    const result = await service.http.put(`/api/reminder/type/multiple-delete`, ids)
    return result
  },
  async getTotalCountType () {
    try {
      const result = await service.http.get(`/api/reminder/type/total-count`)
      return result.data
    } catch (e) {
      return 0
    }
  },
  async getPlansPaginated (pageSize, pageNumber, enterpriseId) {
    let result
    if (pageSize && pageSize > 0 && pageNumber && pageNumber > 0 && enterpriseId) {
      result = await service.http.get(`/api/reminder/plan?pageSize=${pageSize}&pageNumber=${pageNumber}&enterpriseId=${enterpriseId}`)
    } else if (pageSize && pageSize > 0 && pageNumber && pageNumber > 0) {
      result = await service.http.get(`/api/reminder/plan?pageSize=${pageSize}&pageNumber=${pageNumber}`)
    } else {
      result = await this.getAllTypes(enterpriseId)
    }
    return result
  },
  async getAllPlans (enterpriseId) {
    let result
    if (enterpriseId) {
      result = await service.http.get(`/api/reminder/plan?enterpriseId=${enterpriseId}`)
    } else {
      result = await service.http.get(`/api/reminder/plan`)
    }
    return result.data
  },
  async getAllPlansByEnterprises (enterpriseIds) {
    let result
    result = await service.http.post(`/api/reminder/plan/enterprises`, enterpriseIds)
    return result.data
  },
  async getPlansById (id) {
    const result = await service.http.get(`/api/reminder/plan/${id}`)
    return result
  },
  async createPlan (plan) {
    const result = await service.http.post('/api/reminder/plan', plan)
    return result
  },
  async updatePlan (plan) {
    const result = await service.http.put('/api/reminder/plan', plan)
    return result
  },
  async deletePlan (id) {
    const result = await service.http.delete(`/api/reminder/plan/${id}`)
    return result
  },
  async changePlanVisibility (id, visible, enterpriseId) {
    const result = await service.http.put(`/api/reminder/plan/change-visibility`, { id, visible, enterpriseId })
    return result
  },
  async multipleDeletePlan (ids) {
    const result = await service.http.put(`/api/reminder/plan/multiple-delete`, ids)
    return result
  },
  async getTotalCountPlan () {
    try {
      const result = await service.http.get(`/api/reminder/plan/total-count`)
      return result.data
    } catch (e) {
      return 0
    }
  },
  async getThingsReminderConfiguration (ids) {
    const result = await service.http.post('/api/reminder/configurations', ids)
    return result
  },
  async createConfirmation (confirmation) {
    const result = await service.http.post('/api/reminder/confirmation', confirmation)
    return result
  },
  async getHistoryReport (from, to, objectIds, typeIds) {
    const result = await service.http.post('/api/reminder/history-report', { from, to, objectIds, typeIds })
    return result.data
  },
  async getReminderSummary () {
    const result = await service.http.get('/api/reminder/summary')
    return result.data
  },
  async getReminderDetail (objectId) {
    const result = await service.http.get('/api/reminder/detail', { params: { objectId } })
    return result.data
  }
}

export default reminderApi
